var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"quickScore"},[_c('div',[(_vm.home == false)?_c('div',[_c('b-row',[(_vm.tmparamsMini.multi_course === 'Y')?_c('b-col',{staticClass:"col-md-6 multi"},_vm._l((_vm.tmparamsMini.courses.courses_entry),function(multi,index){return _c('span',{key:index,staticClass:"multiList"},[_c('span',{staticClass:"ballpin",class:multi.course_X_colour},[_vm._v("•")]),_c('span',{staticClass:"multiName"},[_vm._v(_vm._s(multi.course_X_id))])])}),0):_vm._e()],1)],1):_vm._e(),(_vm.multi_tourns == 'Y' && _vm.home === true)?[_c('b-row',{staticClass:"pastbtnRow"},[_c('b-col',{staticClass:"tabBtn padRight",attrs:{"col":"12","lg":"6"}},[_c('b-button',{staticClass:"pastbtn",class:{
              activeBtn: _vm.course === _vm.tm.past_tourns.past_tourns_entry.code
            },on:{"click":function($event){return _vm.swapTour(_vm.tm.past_tourns.past_tourns_entry.code)}}},[_vm._v(_vm._s(_vm.tm.past_tourns.past_tourns_entry.name))])],1),_c('b-col',{staticClass:"tabBtn padLeft",attrs:{"col":"12","lg":"6"}},[_c('b-button',{staticClass:"pastbtn",class:{ activeBtn: _vm.course === _vm.tm.code },on:{"click":function($event){return _vm.swapTour(_vm.tm.code)}}},[_vm._v(_vm._s(_vm.tm.short_name))])],1)],1)]:_vm._e(),_c('ReportTable',{attrs:{"data":_vm.data,"title":_vm.title,"home":_vm.home,"updown":_vm.updown,"config":_vm.config,"team":_vm.teamEvent,"match":_vm.match,"Stableford":_vm.Stableford,"code":_vm.courseCode,"Year":_vm.Year,"season":_vm.Year}}),(_vm.title.includes('Entries'))?[_c('b-row',[_c('b-col',{staticClass:"padRight",class:{noPad : _vm.reports.report_title.length}},[_c('div',{staticClass:"FullListingBtn",class:{pulse: _vm.live_scoring == 'Y' && _vm.title.includes('Score'), liveScoring : _vm.live_scoring == 'Y' && _vm.title.includes('Score')}},[_c('b-button',{attrs:{"to":{
                name: 'reports',
                query: {
                  url: _vm.currentReport,
                  id: _vm.Year,
                  code: _vm.courseCode,
                  title: _vm.title
                }
              }}},[_vm._v(" Entries ")])],1)]),(Array.isArray(_vm.reports))?_c('b-col',{staticClass:"padLeft"},[_c('div',{staticClass:"FullListingBtn"},[_c('b-button',{attrs:{"to":{
                name: 'reports',
                query: {
                  url: 'http://asian.ocs-asia.com/tic/tmdraw.cgi?tourn=' + _vm.courseCode+ '~season=' + _vm.Year + '~round=' + '1' + '~',
                  id: _vm.Year,
                  code: _vm.courseCode,
                }
              }}},[[_vm._v(" Draw ")]],2)],1)]):_vm._e()],1)]:(_vm.title.includes('Score'))?[_c('b-row',[_c('b-col',{staticClass:"padRight"},[_c('div',{staticClass:"FullListingBtn",class:{pulse: _vm.live_scoring == 'Y' && _vm.title.includes('Score'), liveScoring : _vm.live_scoring == 'Y' && _vm.title.includes('Score')}},[_c('b-button',{attrs:{"to":{
                name: 'reports',
                query: {
                  url: _vm.currentReport,
                  id: _vm.Year,
                  code: _vm.courseCode,
                  title: _vm.title
                }
              }}},[_vm._v(" Live Score ")])],1)]),_c('b-col',{staticClass:"padLeft"},[_c('div',{staticClass:"FullListingBtn"},[_c('b-button',{attrs:{"to":{
                name: 'reports',
                query: {
                  url: 'http://asian.ocs-asia.com/tic/tmdraw.cgi?tourn=' + _vm.courseCode+ '~season=' + _vm.Year + '~round=' + _vm.data.rounds_played + '~',
                  id: _vm.Year,
                  code: _vm.courseCode,
                }
              }}},[[_vm._v(" Draw ")]],2)],1)])],1)]:[_c('div',{staticClass:"FullListingBtn"},[_c('b-button',{attrs:{"to":{
            name: 'reports',
            query: {
              url: _vm.currentReport,
              id: _vm.Year,
              code: _vm.courseCode,
              title: _vm.title
            }
          }}},[(_vm.title.includes('Draw'))?[_vm._v(" See Full Draw Listing ")]:(_vm.title.includes('Result'))?[_c('span',[_vm._v("Final Result")])]:_vm._e()],2)],1)]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }